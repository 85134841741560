import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithNavigate from '../../auth/Auth0ProviderWithNavigate';
import { AppContextProvider } from '../../context';
import useApolloClient from '../../utils/hooks/useApolloClient';
import { ToastProvider } from 'react-toast-notifications'
import { ApolloProvider } from '@apollo/client';
import App from '../App';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// styles
import { StyleRoot, Style } from 'radium';
import normalize from 'radium-normalize';
// fontawesome
import IconLibrary from '../../utils/IconLibrary';
IconLibrary();

//Backup is Running
const Global = () => {
	// const baseUri = 'https://k7yusdyjka.execute-api.us-east-1.amazonaws.com/master';
	// const baseUri = 'https://f8fjn5bgw2.execute-api.us-east-1.amazonaws.com/prod';
	// const baseUri = 'https://28ohcsi2ph.execute-api.us-east-1.amazonaws.com/running';
	const baseUri = 'https://y0fskvo9th.execute-api.us-east-1.amazonaws.com/staging';
	// const baseUri = 'http://localhost:4000/dev';

	const apolloUri = `${baseUri}/graphql`
	const client = useApolloClient(apolloUri);

	return (
		<BrowserRouter>
			<ApolloProvider client={client}>
				<Auth0ProviderWithNavigate>
					<AppContextProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<ToastProvider placement='bottom-right'>
								<StyleRoot>
									<Style rules={normalize} />
									<App uri={baseUri}/>
								</StyleRoot>
							</ToastProvider>
						</LocalizationProvider>
					</AppContextProvider>
				</Auth0ProviderWithNavigate>
			</ApolloProvider>   
		</BrowserRouter>
	);
}

export default Global;